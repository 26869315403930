<template>
  <b-row class="match-height">
    <b-col cols="12">
      <b-card :title="this.$route.meta.title">
        <b-row>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>No SPPD</h6>
            <p class="card-text mb-25">
              {{ sppd.sppd_number || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>{{ employee.type === 'pegawai' ? 'NIPPOS' : 'NIK' }} / Nama</h6>
            <p class="card-text mb-25">
              {{ employee.type === 'pegawai' ? employee.nippos : employee.nik }}
              /
              {{ employee.type === 'pegawai' ? employee.nama : employee.name }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Tanggal Diajukan</h6>
            <p class="card-text mb-25">
              {{ sppd.submission_date || '-' | moment('DD MMMM YYYY') }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Jenis Fasilitas</h6>
            <p class="card-text mb-25">
              {{ sppdFacilityType }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Jabatan</h6>
            <p class="card-text mb-25">
              {{
                employee.type === 'pegawai'
                  ? employee.descjabatan
                  : employee.position
              }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Bagian/Unit Kerja</h6>
            <p class="card-text mb-25">
              {{ employee.namabagian || '-' }}
            </p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Nomor Rekening</h6>
            <p class="card-text mb-25">{{ kode_rekening }}</p>
          </b-col>
          <b-col cols="12" md="6" xl="4" class="mb-2">
            <h6>Status</h6>
            <b-form-select
              v-if="sppd.status_paid === 1"
              v-model="selectedStatus"
              :options="statusOptionsFiltered"
            ></b-form-select>
            <p v-else class="card-text mb-25">
              {{ sppd.status_paid_detail || '-' }}
            </p>
          </b-col>
          <b-col cols="6" class="mb-2 text-left">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="secondary"
              :disabled="loadingPrint"
              @click="print"
            >
              Cetak
            </b-button>
          </b-col>
          <b-col cols="6" class="mb-2 text-right" v-if="showApproveAllButton">
            <b-button
              v-if="can('approval_pa/bukti-bayar')"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="success"
              @click="approveAll"
            >
              Approve All
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="mb-3">
            <b-table
              striped
              hover
              responsive
              show-empty
              :items="items"
              :fields="fields"
            >
              <template #cell(no)="data">{{ data.index + 1 }}</template>

              <template #cell(type)="data">{{
                capitalizing(data.item.type)
              }}</template>

              <template #cell(total)="data">
                {{ data.item.total | currency }}
              </template>

              <template #cell(status)="data">
                <span
                  v-if="
                    data.item.status === null ||
                    (data.item.type === 'uang harian' && !data.item.status)
                  "
                >
                  Menunggu Approval
                </span>
                <span v-else-if="data.item.status == 1" class="text-success">
                  Disetujui
                </span>
                <span v-else class="text-danger">Ditolak</span>
              </template>

              <template #cell(action)="data">
                <div style="min-width: 120px">
                  <b-button
                    v-if="
                      data.item.type !== 'uang harian' && data.item.status != 1
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.modal-edit-payment
                    v-b-popover.hover.bottom="'Edit'"
                    variant="warning"
                    size="sm"
                    class="btn-icon mr-1"
                    @click="showEditModal(data.item.type)"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>

                  <b-button
                    v-if="data.item.type !== 'uang harian'"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-modal.modal-detail
                    v-b-popover.hover.bottom="'Detail'"
                    variant="info"
                    size="sm"
                    class="btn-icon mr-1"
                    @click="showDetailModal(data.item.type)"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>

                  <b-button
                    v-if="
                      (data.item.status === null ||
                        (data.item.type === 'uang harian' &&
                          !data.item.status)) &&
                      can('approval_pa/bukti-bayar')
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Approve'"
                    variant="success"
                    size="sm"
                    class="btn-icon mr-1"
                    @click="showApproveModal(data.item.type)"
                  >
                    <feather-icon icon="CheckIcon" />
                  </b-button>

                  <b-button
                    v-if="
                      data.item.status === null &&
                      data.item.type !== 'uang harian' &&
                      can('approval_pa/bukti-bayar')
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    v-b-popover.hover.bottom="'Reject'"
                    variant="danger"
                    size="sm"
                    class="btn-icon mr-1"
                    @click="showRejectModal(data.item.type)"
                  >
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="pl-2 mb-2">
            <h6 class="mb-25">Jumlah yang Dibayarkan</h6>
            <p class="card-text mb-25">{{ totalCost | currency }}</p>
          </b-col>

          <b-col cols="12" class="pl-2 mb-2">
            <p class="mb-25">Mengetahui</p>
            <div v-if="sppd.approval_payment_admin">
              <p class="card-text mb-25">
                {{ sppd.approval_payment_admin.employee || '-' }} /
                {{ sppd.approval_payment_admin.name || '-' }} /
                {{ sppd.approval_payment_admin.position || '-' }}
              </p>
              <p class="font-italic" style="font-size: 12px">
                {{
                  sppd.approval_payment_admin.created_at
                    | moment('DD/MM/YYYY HH:mm:ss')
                }}
              </p>
            </div>
            <p v-else>-</p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2">
            <p class="mb-25">Yang Membayar</p>
            <div v-if="sppd.approval_payment_superior">
              <p class="card-text mb-25">
                {{ sppd.approval_payment_superior.employee || '-' }} /
                {{ sppd.approval_payment_superior.name || '-' }} /
                {{ sppd.approval_payment_superior.position || '-' }}
              </p>
              <p class="font-italic" style="font-size: 12px">
                {{
                  sppd.approval_payment_superior.created_at
                    | moment('DD/MM/YYYY HH:mm:ss')
                }}
              </p>
            </div>
            <p v-else>-</p>
          </b-col>
          <b-col cols="12" class="pl-2 mb-2">
            <p class="mb-25">Yang Menerima</p>
            <p class="card-text mb-25">
              {{
                employee.type === 'non-pegawai'
                  ? `${employee.nik || '-'} / ${employee.name || '-'} / ${
                      employee.position || '-'
                    }`
                  : `${employee.nippos || '-'} / ${employee.nama || '-'} / ${
                      employee.descjabatan || '-'
                    }`
              }}
            </p>
          </b-col>
        </b-row>
        <b-card-footer align="right">
          <!-- back -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mr-1"
            variant="outline-secondary"
            :to="{ name: 'bukti-bayar' }"
          >
            Kembali
          </b-button>
          <b-button
            v-if="sppd.status_paid !== 2 && sppd.status_paid !== 3"
            :disabled="selectedStatus === null"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            class="mr-1"
            variant="primary"
            @click="save"
          >
            Simpan
          </b-button>
        </b-card-footer>
        <b-modal
          id="modal-edit-payment"
          ok-title="Simpan"
          cancel-title="Batal"
          :title="modalEditTitle"
          hide-header-close
          @ok="handleEditCostComponent"
        >
          <!-- eslint-disable vue/no-use-v-if-with-v-for -->
          <b-row
            v-if="item.status !== 0"
            class="mt-2"
            v-for="item in modalEditData"
            :key="item.id"
          >
            <b-col cols="6">
              <h6>Biaya</h6>
              <vue-numeric
                id="total_day"
                v-model="item.total_cost"
                @keypress.native="numbersOnly"
                class="form-control"
                placeholder="Masukkan Total Biaya"
                currency="Rp"
                separator="."
              ></vue-numeric>
            </b-col>
            <b-col
              cols="3"
              v-if="
                currentCostType === 'pengeluaran riil' ||
                currentCostType === 'akomodasi' ||
                currentCostType === 'transportasi'
              "
            >
              <h6>Status</h6>
              <span
                v-if="
                  currentCostType === 'pengeluaran riil' && item.status === null
                "
              >
                Menunggu Approval
              </span>
              <span v-else-if="item.status == 1" class="text-success">
                Disetujui
              </span>
              <div
                v-else-if="
                  item.status === null && can('approval_pa/bukti-bayar')
                "
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-popover.hover.bottom="'Approve'"
                  variant="success"
                  size="sm"
                  class="btn-icon mr-1"
                  @click="approvePerComponent(item.ref_id, 1)"
                >
                  <feather-icon icon="CheckIcon" />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-popover.hover.bottom="'Reject'"
                  variant="danger"
                  size="sm"
                  class="btn-icon"
                  @click="approvePerComponent(item.ref_id, 0)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
              <span v-else class="text-danger">Ditolak</span>
            </b-col>
            <b-col
              cols="3"
              v-if="
                currentCostType === 'pengeluaran riil' ||
                currentCostType === 'akomodasi' ||
                currentCostType === 'transportasi'
              "
            >
              <h6>Kwitansi</h6>
              <b-link
                :href="item.file"
                target="_blank"
                class="btn-link text-info"
              >
                Cek Kwitansi
              </b-link>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col cols="12">
              <h6>Catatan (Opsional)</h6>
              <b-form-textarea rows="3" max-rows="6"></b-form-textarea>
            </b-col>
          </b-row>
        </b-modal>
        <b-modal id="modal-detail" title="Detail Catatan" hide-footer>
          <div v-if="modalDetailData.length > 0">
            <div
              v-for="(item, index) in modalDetailData"
              :key="item.id"
              :class="index > 0 ? 'mt-2 pt-2 border-top' : 'mt-1'"
            >
              <h6>{{ item.updated_at | moment('DD/MM/YYYY') }}</h6>
              <p class="font-italic">{{ item.remark }}</p>
              <div v-if="item.note">
                <p class="mb-0 font-weight-bold">Catatan:</p>
                <p>{{ item.note }}</p>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="font-italic my-1 text-center">Belum ada catatan</p>
          </div>
        </b-modal>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.mx-datepicker {
  width: 100% !important;
}
</style>

<script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardFooter,
  BTable,
  BModal,
  BLink,
  BFormTextarea,
  BFormSelect,
  VBPopover,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'

export default {
  components: {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BButton,
    BTable,
    BModal,
    BLink,
    BFormTextarea,
    BFormSelect,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
    'b-popover': VBPopover,
  },
  data() {
    return {
      name: 'BuktiBayarList',
      errors: {},
      showDismissibleAlert: false,
      config: {
        api: '/payments',
        redirect: '/bukti-bayar',
      },
      sppd: {},
      fields: [
        'no',
        { key: 'type', label: 'Komponen Biaya' },
        { key: 'note', label: 'Rincian' },
        { key: 'total', label: 'Total' },
        { key: 'status', label: 'Status' },
      ],
      items: [],
      modalEditTitle: '',
      modalEditData: [],
      modalDetailData: [],
      costTypes: [
        { key: 'pengeluaran riil', label: 'Pengeluaran Riil' },
        { key: 'akomodasi', label: 'Biaya Akomodasi' },
        { key: 'transportasi', label: 'Biaya Transportasi' },
        { key: 'sumbangan pindah', label: 'Biaya Sumbangan Pindah' },
        { key: 'biaya pemeriksaan', label: 'Biaya Pemeriksaan' },
      ],
      currentCostType: '',
      totalCost: 0,
      messages: '',
      selectedStatus: null,
      statusOptions: [{ value: null, text: 'Pilih Status' }],
      loadingPrint: false,
    }
  },
  computed: {
    sppdFacilityType() {
      if (this.sppd.sppd_facility_type_id == 1) {
        return 'Perjalanan Dinas Biasa'
      }
      if (this.sppd.sppd_facility_type_id == 2) {
        return 'Perjalanan Dinas Fasilitas Khusus'
      }
      return '-'
    },
    employee() {
      return this.sppd.employee?.raw ? JSON.parse(this.sppd.employee.raw) : {}
    },
    showApproveAllButton() {
      if (
        this.items.length > 0 &&
        this.items.some(item => item.status === null)
      ) {
        return true
      }
      return false
    },
    statusOptionsFiltered() {
      if (
        this.sppd.status_paid_detail === 'Menunggu Dibayarkan' &&
        this.statusOptions.length > 1
      ) {
        const tempStatusOptions = [...this.statusOptions].filter(
          ({ text }) => text !== 'Menunggu Approval',
        )
        return tempStatusOptions
      }

      return this.statusOptions
    },
    kode_rekening() {
      return this.sppd.employee?.no_rek || '-'
    },
  },
  mounted() {
    this.getStatusOptions()
    this.getData()
  },
  methods: {
    getData() {
      axios.get(`${this.config.api}/${this.$route.params.id}`).then(res => {
        const { data } = res.data
        this.sppd = data
        this.items = data.payments.filter(({ status }) => status !== 0)
        this.totalCost = data.total_payment
        this.selectedStatus = data.status_paid

        if (data.status_paid !== 2 && data.status_paid !== 3) {
          this.fields.push({
            key: 'action',
            label: 'Aksi',
            thStyle: 'width: 260px',
          })
        }
      })
    },
    getStatusOptions() {
      axios.get('/misc/status-paid').then(res => {
        const data = res.data.data.map(({ id, name }) => ({
          value: id,
          text: name,
        }))
        this.statusOptions = [...this.statusOptions, ...data]
      })
    },
    print() {
      this.loadingPrint = true
      axios
        .get(`payments/${this.sppd.id}/print`, {
          responseType: 'blob',
        })
        .then(res => {
          const url = window.URL.createObjectURL(
            new Blob([res.data], { type: 'application/pdf' }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            `Bukti-Pembayaran-${this.sppd.sppd_number}.pdf`,
          )
          document.body.appendChild(link)
          this.loadingPrint = false
          link.click()
          link.remove()
        })
        .catch(error => {
          this.loadingPrint = false
          console.error(error)
        })
    },
    capitalizing(text) {
      let words = text.toLowerCase().split(' ')
      words = words
        .map(word => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(' ')
      return words
    },
    save() {
      this.$swal({
        title: 'Perhatian',
        text: 'Saya menyatakan dokumen yang diupload adalah benar/sah. Jika tidak benar, maka saya bersedia dikenakan sanksi/hukdis sesuai dengan ketentuan yang berlaku',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, setuju',
        cancelButtonText: 'Tidak, batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          const data = { status: this.selectedStatus }
          axios
            .put(`${this.config.api}/${this.$route.params.id}`, data)
            .then(() => {
              this.makeToast(
                'success',
                'Update Bukti Bayar',
                'Status bukti bayar berhasil diubah',
              )
              if (this.selectedStatus === 2 || this.selectedStatus === 3) {
                this.fields.pop()
              }
              this.getData()
            })
            .catch(err => console.error(err))
        }
      })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
    showEditModal(type) {
      this.modalEditTitle = `Ubah ${
        this.costTypes.find(({ key }) => key === type).label
      }`
      this.currentCostType = type
      axios
        .get(`sppd/payment/${this.$route.params.id}?type=${type}`)
        .then(res => {
          this.modalEditData = res.data.data
        })
        .catch(err => console.error(err))
    },
    showDetailModal(type) {
      axios
        .get(`sppd/payment/history/${this.$route.params.id}?type=${type}`)
        .then(res => {
          this.modalDetailData = res.data.data
        })
        .catch(err => console.error(err))
    },
    approveAll() {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui semua biaya tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          axios
            .put(`${this.config.api}/${this.$route.params.id}/approve/all`)
            .then(() => {
              this.makeToast(
                'success',
                'Update Bukti Bayar',
                'Semua biaya berhasil disetujui',
              )
              this.getData()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal mengirim data',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    showApproveModal(type) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menyetujui biaya tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          const url = `${this.config.api}/${this.$route.params.id}/${
            type === 'uang harian' ? 'daily-money' : 'approve'
          }`

          const body = type === 'uang harian' ? { status: 1 } : { type }

          axios
            .put(url, body)
            .then(() => {
              this.makeToast(
                'success',
                'Update Bukti Bayar',
                'Biaya berhasil disetujui',
              )
              this.getData()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal mengirim data',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    showRejectModal(type) {
      this.$swal({
        title: 'Apakah Anda Yakin',
        text: 'untuk menolak biaya tersebut?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batal',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-2',
        },
      }).then(result => {
        if (result.value) {
          axios
            .put(`${this.config.api}/${this.$route.params.id}/reject`, { type })
            .then(() => {
              this.makeToast(
                'success',
                'Update Bukti Bayar',
                'Biaya berhasil ditolak',
              )
              this.getData()
            })
            .catch(err => {
              if (err.response) {
                this.$swal({
                  icon: 'Danger',
                  title: 'Gagal mengirim data',
                  text: err.response.data.message,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
    handleEditCostComponent() {
      const data = {
        payments: this.modalEditData.map(({ id, total_cost }) => ({
          id,
          total_cost,
        })),
      }

      axios
        .put(`sppd/payment/${this.$route.params.id}`, data)
        .then(() => {
          this.makeToast(
            'success',
            'Update Bukti Bayar',
            'Data komponen biaya berhasil diubah',
          )
          this.getData()
        })
        .catch(err => console.error(err))
    },
    numbersOnly(event) {
      const evt = event || window.event
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    approvePerComponent(id, status) {
      const url = `/sppd/${
        this.currentCostType === 'transportasi'
          ? 'transportation'
          : 'accomondation'
      }/${id}/approve`

      axios
        .put(url, { status })
        .then(() => {
          this.makeToast(
            'success',
            'Update Bukti Bayar',
            `Data komponen biaya berhasil ${
              status === 1 ? 'disetujui' : 'ditolak'
            }`,
          )
          this.modalEditData = [...this.modalEditData].map(item => {
            if (item.ref_id === id) {
              /* eslint-disable-next-line */
              item.status = status
            }
            return item
          })
          this.getData()
        })
        .catch(err => console.error(err))
    },
  },
}
</script>
